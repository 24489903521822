import {
    fetch,
    normalize,
} from "../../utils/dataAccess";
import {success as createSuccess} from "./create";
import {loading, error} from "./delete";
import persist from "./persister";
import hydrator from "./hydrator";

export function retrieveError(retrieveError) {
    return {type: "FEED_UPDATE_RETRIEVE_ERROR", retrieveError};
}

export function retrieveLoading(retrieveLoading) {
    return {type: "FEED_UPDATE_RETRIEVE_LOADING", retrieveLoading};
}

export function retrieveSuccess(retrieved) {
    return {type: "FEED_UPDATE_RETRIEVE_SUCCESS", retrieved};
}

export function retrieve(id) {
    return dispatch => {
        dispatch(retrieveLoading(true));
        dispatch(retrieveError(""));

        return fetch(id)
            .then(response =>
                response
                    .json()
                    .then(retrieved => ({retrieved}))
            )
            .then(({retrieved}) => {
                retrieved = hydrator(retrieved);
                retrieved = normalize(retrieved);

                dispatch(retrieveLoading(false));
                dispatch(retrieveSuccess(retrieved));
            })
            .catch(e => {
                dispatch(retrieveLoading(false));
                dispatch(retrieveError(e.message));
            });
    };
}

export function updateError(updateError) {
    return {type: "FEED_UPDATE_UPDATE_ERROR", updateError};
}

export function updateLoading(updateLoading) {
    return {type: "FEED_UPDATE_UPDATE_LOADING", updateLoading};
}

export function updateSuccess(updated) {
    return {type: "FEED_UPDATE_UPDATE_SUCCESS", updated};
}

export function update(item, values) {
    return dispatch => {
        dispatch(updateError(null));
        dispatch(error(null));
        dispatch(updateSuccess(null));
        dispatch(updateLoading(true));

        values = persist(values);

        return fetch(item["@id"], {
            method: "PUT",
            headers: new Headers({"Content-Type": "application/ld+json"}),
            body: JSON.stringify(values)
        })
            .then(response =>
                response
                    .json()
                    .then(retrieved => ({retrieved}))
            )
            .then(({retrieved}) => {
                retrieved = normalize(retrieved);

                dispatch(updateLoading(false));
                dispatch(updateSuccess(retrieved));
            })
            .catch(e => {
                dispatch(updateLoading(false));
                dispatch(updateError(e));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch({type: "FEED_UPDATE_RESET"});
        dispatch(error(null));
        dispatch(loading(false));
        dispatch(createSuccess(null));
    };
}
