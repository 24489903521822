import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";

// @mui/material components
import Tooltip from "@mui/material/Tooltip";
import {Grid} from "@mui/material";

// @mui/icons-material
import {
    Delete,
    Publish
} from "@mui/icons-material";

// core components
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";

// utils
import OptionValueName from "./OptionValueName";
import RetailerOptionValueName from "./RetailerOptionValueName";
import MappingElementContainer from "./MappingElementContainer";
import OptionValueListButton from "./OptionValueListButton";
import OptionValueListButtonIcon from "./OptionValueListButtonIcon";
import MappingSeparator from "./MappingSeparator";
import OptionValueList from "./OptionValueList";
import MappingActionContainer from "./MappingActionContainer";

import {MappingTabEnums} from "../enums/MappingTabEnums";
import {ProductStepEnums} from "../enums/ProductStepEnums";

// this component is only for categories and genders
class CardAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOptionValuesListOpen: false,
            visible: true,
            selectedValue: null,
        }
    }

    getOptions = attributeName => {
        const {categories, genders} = this.props.attributeRetrieved;

        if (attributeName === "categories" && categories) {
            return categories;
        }  else if (attributeName === "genders" && genders) {
            genders.forEach((item) => {
                item['name'] = item.value;
            });
            return genders;
        }
    };

    toggleOptionValuesList() {
        this.setState({
            isOptionValuesListOpen: !this.state.isOptionValuesListOpen
        });
    }

    closeOptionValuesList() {
        this.setState({
            isOptionValuesListOpen: false
        });
    }

    tagCategoryAsTrash(item) {
        if (item.trash === true) {
            console.log('category is already trash');
            return false;
        }

        if (!window.confirm(this.props.intl.formatMessage({id: "mapper.form.action.tag_category_as_trash"}))) {
            return false;
        }

        let data = {
            '@id': '',
            'name': item['category_dictionary_id'],
            'trash': true,
        };

        this.props.mapValue(data);
    }

    tagCategoryAsUntrash(item) {
        if (item.trash === false) {
            console.log('category is not trash');
            return false;
        }

        if (!window.confirm(this.props.intl.formatMessage({id: "mapper.form.action.tag_category_as_untrash"}))) {
            return false;
        }

        let data = {
            '@id': '',
            'name': item['category_dictionary_id'],
            'untrash': true,
        };

        this.props.mapValue(data);
    }

    displayTrashCategoriesStuff(data) {
        const {activeTab} = this.props;
        const isMappedView = activeTab === MappingTabEnums.MAPPED;

        let content = '';
        if (isMappedView) {
            if (data.trash === false) {
                return;
            }

            content = (
                <div>
                    <Tooltip
                        title={<FormattedMessage id={"mapper.label.untrash_category"}/>}
                    >
                        <Publish onClick={() => this.tagCategoryAsUntrash(data)}/>
                    </Tooltip>
                </div>
            );
        } else {
            content = (<Delete onClick={() => this.tagCategoryAsTrash(data)}/>);
        }

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                "& svg": {
                    fontSize: "25px",
                    cursor: "pointer"
                }
            }}>
                {content}
            </div>
        );
    }

    handleChange = (value) => {
        this.closeOptionValuesList();

        if (this.props.activeTab !== 'mapped' || value.untrash === true) {
            this.setState({ visible: false });
        } else {
            this.setState({ selectedValue: value });
        }

        this.props.mapValue(value);
    }

    render() {
        const { item, labels, labelName, labelNameId, labelData, attributeRetrieved, valuesFromProps } = this.props;

        const {intl, activeStep} = this.props;
        const {visible} = this.state;
        const isCategoryStep = activeStep.index === 0;

        const options = this.getOptions(labels["attributeName"]);
        let mappedValues = false;

        if (labels['attributeName'] === ProductStepEnums.CATEGORIES ) {
            if (options.filter(function (options) {return options[labelNameId] === item[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item[labelNameId]})[0];
                const label = mappedValues.name[mappedValues.name.length - 1];
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        } else if (labels['attributeName'] === ProductStepEnums.GENDERS) {
            if (options.filter(function (options) {return options[labelNameId] === item.gender[labelNameId]}).length > 0) {
                mappedValues = options.filter(function (options) {return options[labelNameId] === item.gender[labelNameId]})[0];
                const label = mappedValues.name;
                mappedValues = {[item[labelName]]: {'@id': mappedValues[labelNameId], 'name': item[labelName], 'label': label, 'value': labels["attributeName"]}};
            }
        }

        // This code is not fixable
        // Increment the counter
        // to warn the next guy.

        // hours_wasted = 15
        let isValue = false;
        if (mappedValues && true !== this.state[item[labelName]] && false !== this.state[item[labelName]]) {
            isValue = true;
        }

        if (!visible) {
            return (<div/>);
        }

        if (options && options.length > 0) {
            for(let i = 0; i <  options.length; i++) {
                if (options[i]["@id"] === "") {
                    options.splice(i, 1);
                }
            }

            options.unshift({"@id": "", "name": intl.formatMessage({id: "mapper.form.options.none"})});
        } else {
            options.push({"@id": "", "name": intl.formatMessage({id: "mapper.form.options.none"})});
        }

        let values = mappedValues && true === isValue ? mappedValues : valuesFromProps;
        let defaultValue = values && values[item[labelName]] ? values[item[labelName]].label : '';
        defaultValue = this.state.selectedValue ? this.state.selectedValue.label : defaultValue;
        let optionsValues = [];
        let labelId = labelNameId;
        let label = '';

        const attributeType = labelData;
        const attributeId = item[labelName];
        const imgUrlsByAttribute = attributeRetrieved?.['imgUrlsByAttributes']?.[attributeType]?.[attributeId];

        options && options.map((item) => {
            if (defaultValue && defaultValue === item["@id"]) {
                defaultValue = (item['name'][item['name'].length-1])
            }

            if (Array.isArray(item['name'])) {
                if (ProductStepEnums.CATEGORIES === labelData) {
                    label = item['name'].join(' > ');
                } else {
                    label = item['name'][item['name'].length-1];
                }
            } else {
                label = item['name'];
            }

            optionsValues.push({
                "@id": item[labelId],
                name: attributeId,
                label: label.toLowerCase(),
                value: item.id
            })

            return item;
        });

        if (optionsValues && typeof optionsValues !== undefined) {
            optionsValues = optionsValues.sort(function (a, b) {
                return a.label.length - b.label.length || a.label.localeCompare(b.label)
            });
        }

        const labelLeft = item['name'] ? item['name'] : item['value'];

        return (
            <MappingElementContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4}>
                        <RetailerOptionValueName
                            retailerOptionValueId={labelNameId}
                            retailerOptionValue={labelLeft}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={3}>
                        <MappingSeparator
                            isMapped={!!defaultValue}
                            links={imgUrlsByAttribute}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={5}>
                        <MappingActionContainer>
                            <Grid
                                item
                                xs={9}
                                sm={9}
                                style={{
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    alignSelf: 'center',
                                }}
                            >
                                {this.state.isOptionValuesListOpen ? (
                                    <OptionValueList
                                        id={labelName}
                                        valuesList={optionsValues}
                                        onChange={(value) => this.handleChange(value)}
                                        onBlur={() => this.closeOptionValuesList()}
                                    />
                                ) : (
                                    <OptionValueName name={defaultValue} />
                                )}
                            </Grid>
                            <GridItem xs={3} sm={3}>
                                <OptionValueListButton onClick={() => this.toggleOptionValuesList()}>
                                    <OptionValueListButtonIcon open={this.state.isOptionValuesListOpen} />
                                </OptionValueListButton>
                            </GridItem>
                            {isCategoryStep && this.displayTrashCategoriesStuff(item)}
                        </MappingActionContainer>
                    </GridItem>
                </GridContainer>
            </MappingElementContainer>
        );
    }
}

export default injectIntl(CardAttribute);
