import {DisableVariationsFeedOptions} from "../../utils/DisableVariationsFeedOptions";

export default function persist(values) {
    if (values.hasOwnProperty('disableVariationsNotSeen')) {
        const isDisabled = values['disableVariationsNotSeen'];

        values['disableVariationsNotSeenInAllFeeds'] = (isDisabled === DisableVariationsFeedOptions.ALL);
        values['disableVariationsNotSeenInThisFeed'] = (isDisabled === DisableVariationsFeedOptions.THIS);

        delete values['disableVariationsNotSeen'];
    }

    return values;
}
