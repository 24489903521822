import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Grid } from '@mui/material';

import Card from 'components/page/Card';
import CardBody from 'components/card/cardBody';
import GridContainer from 'components/grid/gridContainer';
import GridItem from 'components/grid/gridItem';
import CardTitle from 'components/card/CardTitle';
import CardHeader from 'components/card/cardHeader';
import SaveButton from 'components/button/SaveButton';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';

import { updatePatch } from 'api/retailer/update';
import list from 'api/option/list';

import {getTranslation} from "domain/helpers/translations";

Mapping.propTypes = {
    retailer: PropTypes.object.isRequired
};

function Mapping({retailer, ...props}) {
    const [initialValues, setInitialValues] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        list('mapped=true').then((res) => {
            setOptions(res);
            setInitialValues(createInitialValues(res));
        })
    }, [])

    function isRetailerHasOptionMapped(optionId) {
        return retailer?.optionsMapping.some(option => option.id === optionId);
    }

    function createInitialValues(optionList) {
        return Object.fromEntries(
            optionList.map(option => [option['@id'], isRetailerHasOptionMapped(option.id)])
        );
    }

    function getOptionName(optionId) {
        const matchOption = options.find(option => option['@id'] === optionId);

        return getTranslation(matchOption).name;
    }

    function saveRetailerOptionsMapping(values, actions) {
        const selectedOptionsMapping = Object.keys(values).filter(key => values[key] === true);

        setLoading(true)
        setSuccess(false);

        updatePatch(retailer, {
            "optionsMapping": selectedOptionsMapping
        }).then(() => {
            setSuccess(true);
        }).finally(() => {
            actions.setSubmitting(false);
            setLoading(false);
        })
    }

    return (
        <Card>
            <CardHeader color="info">
                <GridContainer>
                    <GridItem xs={9} sm={10}>
                        <CardTitle titleId={'retailer.update.mapping'} />
                    </GridItem>
                </GridContainer>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting}) => {
                        saveRetailerOptionsMapping(values, {setSubmitting});
                    }}
                >
                    {({
                          handleChange,
                          values,
                          isValid,
                          isSubmitting
                    }) => (
                        <Form>
                            <Grid container>
                                {Object.entries(values).map(([optionId, checked]) => {
                                    return (
                                        <Grid item xs={12} key={optionId}>
                                            <CustomCheckbox
                                                noPadding={true}
                                                name={optionId}
                                                checked={checked}
                                                onClick={handleChange}
                                            />
                                            <span style={{marginLeft: '8px'}}>
                                                <FormattedMessage
                                                    id="retailer.update.mapping.option"
                                                    values={{optionName: getOptionName(optionId)}}
                                                />
                                            </span>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <div style={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'baseline',
                                marginTop: '24px'
                            }}>
                                <SaveButton
                                    type={"submit"}
                                    disabled={loading || !isValid || isSubmitting}
                                    helperText={props.intl.formatMessage({id: "retailer.update.mapping.success"})}
                                    showHelperText={isSuccess}
                                >
                                    <FormattedMessage id="retailer.update.mapping.save" />
                                </SaveButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default injectIntl(Mapping);
