import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {DotLoader} from 'react-spinners';
import Cookies from 'js-cookie';

// @mui/material components
import {Fab, InputAdornment, TextField} from "@mui/material";

// @mui/icons-material
import {AddAPhoto, Edit, HighlightOff, NotificationsActive} from "@mui/icons-material";

// core components
import Card from "components/card/card";
import CardBody from "components/card/cardBody";
import GridContainer from "components/grid/gridContainer";
import GridItem from "components/grid/gridItem";
import Snackbar from "../../../../../../components/snackbar/snackbar";

// customs component
import ButtonsWrapper from "../button/buttonsWrapper";
import CustomCreatableSelect from "components/select/creatableSelect";
import LabeledSwitch from "components/switch/LabeledSwitch";

// styles
import CreateProductFormStyle from "assets/jss/views/stockeasy/product/create/form/createProductFormStyle";

// validation
import {createValidation, getUpdatedValidations, getValidationById} from "../form/formValidation";

// actions
import {handleFileUpload, removeFile, reset} from "actions/upload";

// utils
import {getStockEasyLink} from "domain/helpers/StockEasyHelper";
import {formatCornersOptions, formatOptions} from "../form/selectHelper";
import {FormattedMessage, injectIntl} from "react-intl";
import {v4 as uuidv4} from 'uuid';

import {getData} from "utils/responseParser";
import {getDictionaryByType} from "reducers/dictionaries/list";
import {addCategoryFullPath} from "utils/category";
import {capitalize} from "utils/capitalize";
import {sortOn} from "utils/sortOn";
import {isFloat} from "utils/typeValidation";

import localStorageSE from "../localStorageManager/localStorageSE";
import {withStyles} from "@mui/styles";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {
    CKEditorConfigSimple,
    CKEditorConfigSimpleWithTitles
} from "../../../../../../components/input/inputRenderer/wysiwygInputRenderer";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// helpers
import {Authorizations, hasAuthorization} from "../../../../../../utils/authorizations";

class ProductStep extends Component {
    constructor(props) {
        super(props);
        const {brands, categories, organizationCategories, productWithoutBarcode, corners} = this.props;
        const product = localStorageSE.getProductData() ?? false;

        this.state = {
            productData: product ? product : productWithoutBarcode,
            categoriesOptions: formatOptions(product.categories),
            cornersOptions: formatCornersOptions(product.corners, corners, this.props.member.locale),
            brandOptions: formatOptions(product.brand),
            isStepValid: true,
            validations: this.createValidations(product),
            errorMessage: null,
            retailerCategories: categories && organizationCategories ? this.formatRetailerCategories(categories, organizationCategories) : [],
            retailerBrands: brands ? this.formatRetailerBrand(brands) : [],
            retailerCorners: corners ? this.formatCorners(corners) : [],
        }
    }

    formatRetailerBrand(brands) {
        return brands.map(brand => {
            return {
                label: capitalize(brand.value.toLowerCase()),
                value: brand.value
            };
        });
    }

    formatRetailerCategories(categories, organizationCategories) {
        const retailerCategs = categories.map(category => {
            return {
                label: category.value,
                value: category.fullParentPath + category.value // we need to recreate the entire category path
            };
        });

        addCategoryFullPath(organizationCategories);

        const organizationCategs = organizationCategories.filter(option => option.parent !== null).map(category => {
            // fullPath is only for display
            // not the value we send to Mapper.php
            return {
                label: category.fullPath,
                value: category.translations[this.props.member.locale]?.name
            };
        });

        return retailerCategs.concat(organizationCategs)
    }

    formatCorners(corners) {
        if (corners.length === 0) {
            return [];
        }

        return corners.map(corner => {
            return {
                label: corner.translations[this.props.member.locale]?.name,
                value: corner.id
            };
        });
    }

    transformProductData(productData) {
        const newProductData = {...productData};

        delete newProductData['@context'];
        delete newProductData['@id'];
        delete newProductData['@type'];
        delete newProductData['id'];

        if (newProductData.sku === null) {
            newProductData.sku = uuidv4();
        }

        // transform product
        if (!newProductData.price) {
            newProductData.price = 0;
        }

        // transform variations
        let computedVariations = [];

        newProductData.variations.forEach((variation, index) => {
            delete variation['@id'];
            delete variation['@type'];
            // set a default uuid for each variation for validations, etc.
            variation.id = uuidv4();
            variation.stock = 0;

            // get default variation = the one corresponding to the barcode is the one with sku
            if (variation.sku) {
                variation.selected = true;
                variation.default = true;
                if (!variation.price) {
                    variation.price = newProductData.price;
                }
            }

            // if a variation has no selected, we need to set it
            else if ('undefined' === typeof(variation.selected)) {
                variation.selected = false;
                variation.default = false;
                if (!variation.price) {
                    variation.price = newProductData.price;
                }
            }

            // new uploaded pictures return mediaObject, here we only need url
            // add pictures on variations too
            if (newProductData.pictures.length) {
                variation.pictures = newProductData.pictures = newProductData.pictures.map(function(picture) {
                    return picture?.contentUrl ? picture.contentUrl : picture;
                });
            }

            // else variation is already set, we have nothing to do
            computedVariations[index] = variation;
        });

        newProductData.variations = computedVariations;

        this.setState(prevState => {
            return {
                productData: newProductData
            }
        });

        // set state with new variations
        return newProductData;
    }

    createValidations(product) {
        return [
            createValidation('name', product.name, true, 'text', true),
            createValidation('brand', product.brand, true, 'text', true),
            createValidation('description', product.description, true, 'text', true),
            createValidation('categories', formatOptions(product.categories), true, 'select', true),
        ];
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('scannedBarcode');
        searchParams.delete('variation');
        this.props.history.replace(searchParams);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) { //todo: change with componentDidUpdate
        const {productData} = this.state;

        if (this.props.brands !== nextProps.brands) {
            this.setState({
                'retailerBrands': this.formatRetailerBrand(nextProps.brands)
            });
        }

        if (this.props.categories !== nextProps.categories && nextProps.organizationCategories) {
            this.setState({
                'retailerCategories': this.formatRetailerCategories(nextProps.categories, nextProps.organizationCategories),
            });
        }

        if (nextProps.uploadSuccess) {
            productData.pictures.push(nextProps.uploadSuccess)
            this.setState({productData});
            localStorageSE.updateProductData(productData);
            this.props.uploadReset();
        }

        if (nextProps.removeSuccess) {
            productData.pictures.forEach((picture, index) => {
                if (typeof picture === 'object' && picture['@id'] === nextProps.removeSuccess) {
                    delete productData.pictures[index];

                    this.setState({productData});
                    localStorageSE.updateProductData(productData);
                }
            })
        }
    }

    addPictureInProduct(event) {
        this.props.handleFileUpload(event.target.files, "product_picture", null);
    }

    removePictureFromProduct(item) {
        const {productData} = this.state;

        if (window.confirm(this.props.intl.formatMessage({id: "product.form.action.media.remove"}))) {
            if (typeof item === 'object' && item['@id'] !== null) {
                this.props.removeFile(item['@id'])
            } else {
                productData.pictures.splice(productData.pictures.indexOf(item), 1);

                this.setState({productData});
            }
        }
    }

    getDefaultEndAdornment = () => {
        return ({
                endAdornment: (
                    <InputAdornment position="end">
                        <Edit fontSize={"small"}/>
                    </InputAdornment>
                ),
        })
    }

    updateValidations(value, id, validations) {
        this.setState({
            validations: getUpdatedValidations(value, id, validations)
        });
    }

    updateProductData(value, id) {
        this.setState(prevState => ({
            validations: prevState.validations,
            productData: {
                ...prevState.productData, [id]: value
            }
        }));
    }

    handleTextChange(value, id) {
        // we set the new value of the product and we check the validity of the form field content
        this.updateProductData(value, id);
        this.updateValidations(value, id, this.state.validations);
    }

    handleAdditionalAttributes(event, id, type) {
        if ('float' === type) {
            if (event.target.value !== '' && !isFloat(event.target.value)) {
                this.setState({[`additionalFieldsInput${id}`]: event.target.value.replace(/[^\d.]/g, '')});

                return;
            }
        }
        this.setState({[`additionalFieldsInput${id}`] : event.target.value});
        let additionalAttributes = this.state.productData.additionalAttributes ?? {};
        additionalAttributes[id] = event.target.value;

        this.setState(prevState => ({
            productData: {
                ...prevState.productData, ['additionalAttributes']: additionalAttributes
            }
        }));
    }

    handleCategoriesChange(categories) {
        const {productData, validations} = this.state;
        let newCategories = [];

        if (categories && categories.length > 0) {
            categories.forEach(category => {
                newCategories.push(category.value);
            });
        }
        productData.categories = newCategories;

        this.setState({productData});
        this.updateValidations(newCategories, 'categories', validations)
    }

    handleCornersChange(corners) {
        const {productData} = this.state;
        let newCorners = [];

        if (corners && corners.length > 0) {
            corners.forEach(corner => {
                newCorners.push(corner.value);
            });
        }
        productData.corners = newCorners;

        this.setState({productData});
    }

    handleBrandChange(brandOption) {
        const {productData, validations} = this.state;
        productData.brand = brandOption.value;

        this.setState({productData});
        this.updateValidations(brandOption.value, 'brand', validations)
    }

    handleSwitchCatalogOnlyFeatureProperty(event, property) {
        const {productData} = this.state;

        productData[property] = event.target.checked

        this.setState({productData});
    }

    handleConfirmStep() {
        const {productData, validations} = this.state;
        const {intl} = this.props;

        // add selected and default to variations
        const result = this.transformProductData(productData);

        if (this.checkValidations(validations)) {
            localStorageSE.updateProductData(result);
            this.props.goToNextStep(); // redirect to optionsStep step
        } else {
            this.setState({errorMessage: intl.formatMessage({id: "stockeasy.error.validation"})});
        }
    }

    checkValidations(validations) {
        let result = true;

        validations.map(field => {
            // compute validity for all fields at first
            this.updateValidations(field.value, field.id, validations);

            if (!field.valid) {
                result = false;
            }
        })
        return result;
    }

    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    onDragEnd(result) {
        const {productData} = this.state;

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        productData.pictures = this.reorder(
            productData.pictures,
            result.source.index,
            result.destination.index
        );

        this.setState({productData});
    }

    render() {
        const {classes, intl, retailer, authorizations} = this.props;
        const {productData, categoriesOptions, cornersOptions, brandOptions, validations, errorMessage} = this.state;

        const member = Cookies.get("_c") ? JSON.parse(Cookies.get("_c").toString()) : null;
        let HTMLPurifierConfig = CKEditorConfigSimple;
        if (member && member['organizationIsTitleTagsAllowedInProductDescription']) {
            HTMLPurifierConfig = CKEditorConfigSimpleWithTitles;
        }

        const additionalMappingFields = null !== retailer ? retailer.additional_mapping_fields : null;
        const canManageCatalogSettings = (hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS) && hasAuthorization(authorizations, Authorizations.STOCK_EASY_CATALOG_PRODUCT_SETTINGS)) || !hasAuthorization(authorizations, Authorizations.STOCK_EASY_ACCESS);

        return (
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} className={classes.imageSlider}>
                            <DragDropContext onDragEnd={(res) => this.onDragEnd(res)}>
                                <Droppable droppableId={"list"} direction={"horizontal"}>
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={classes.droppable}
                                        >
                                            {productData.pictures.map((item, index) => {
                                                let url = typeof item === 'object' && item?.contentUrl !== null
                                                    ? item.contentUrl
                                                    : item

                                                return (
                                                    <Draggable key={url} index={index} draggableId={url}>
                                                        {provided => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={classes.imageContainer}
                                                            >
                                                                <img src={url} alt=""/>
                                                                <HighlightOff
                                                                    fontSize={"large"}
                                                                    className={classes.slickRemove}
                                                                    onClick={() => this.removePictureFromProduct(item)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {this.props.uploadError && (
                                <Snackbar
                                    autoHideDuration={5000}
                                    onClose={() => this.props.uploadReset()}
                                    open={!!this.props.uploadError}
                                    close
                                    closeNotification={() => this.props.uploadReset()}
                                    place={"bl"}
                                    color={"danger"}
                                    icon={function () {
                                        return <NotificationsActive/>
                                    }}
                                    message={<FormattedMessage id={this.props.uploadError}/>}
                                />
                            )}

                            {this.props.uploadLoading && (
                                <DotLoader
                                    sizeUnit={"px"}
                                    size={40}
                                    color={"#0066FF"}
                                    loading
                                />
                            )}
                            {!this.props.uploadLoading && (
                                <GridItem xs={2} md={1}>
                                    <input
                                        accept="image/*"
                                        onChange={(e) => this.addPictureInProduct(e)}
                                        id="icon-button-file"
                                        type="file"
                                        className={classes.addImageInput}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <Fab
                                            size={"small"}
                                            color={"primary"}
                                            aria-label="upload picture"
                                            component="span"
                                        >
                                            <AddAPhoto fontSize={"small"} />
                                        </Fab>
                                    </label>
                                </GridItem>
                            )}
                        </GridItem>
                        <GridItem xs={12}>
                            <TextField
                                id={"name"}
                                label={intl.formatMessage({id: "stockeasy.product.name.label"})}
                                placeholder={intl.formatMessage({id: "stockeasy.product.name.placeholder"})}
                                variant="outlined"
                                error={!getValidationById('name', validations).valid}
                                onChange={(e) => this.handleTextChange(e.target.value, 'name')}
                                defaultValue={productData.name && productData.name}
                                InputLabelProps={{shrink: true}}
                                fullWidth
                                required
                                InputProps={this.getDefaultEndAdornment()}
                                inputProps={{maxLength: 255}}
                                className={classes.textField}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomCreatableSelect
                                id="brand"
                                label={intl.formatMessage({id: "stockeasy.product.brand.label"})}
                                required
                                onChange={(brandOption) => this.handleBrandChange(brandOption)}
                                options={brandOptions.concat(this.state.retailerBrands).sort(sortOn("label"))}
                                defaultValue={brandOptions}
                                formatCreateLabel={(name) => intl.formatMessage({id: "stockeasy.product.brand.create"})+' "'+name+'"'}
                                className={classes.textField}
                                error={!getValidationById('brand', validations).valid}
                                noOptionsMessage={intl.formatMessage({id: "stockeasy.product.brand.add_brand"})}
                                placeholder={intl.formatMessage({id: "stockeasy.product.brand.select_brand"})}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomCreatableSelect
                                id="categories"
                                label={intl.formatMessage({id: "stockeasy.product.categories.label"})}
                                isMulti
                                required
                                onChange={(options) => this.handleCategoriesChange(options)}
                                options={categoriesOptions.concat(this.state.retailerCategories).sort(sortOn("label"))}
                                defaultValue={categoriesOptions}
                                formatCreateLabel={(name) => intl.formatMessage({id: "stockeasy.product.categories.create"})+' "'+name+'"'}
                                className={classes.textField}
                                error={!getValidationById('categories', validations).valid}
                                noOptionsMessage={intl.formatMessage({id: "stockeasy.product.categories.add_category"})}
                                placeholder={intl.formatMessage({id: "stockeasy.product.categories.select_category"})}
                            />
                        </GridItem>
                        <GridItem xs={12} style={{marginBottom: '1.2em'}}>
                            <CustomCreatableSelect
                                id="corners"
                                label={intl.formatMessage({id: "stockeasy.product.corners.label"})}
                                isMulti
                                onChange={(options) => this.handleCornersChange(options)}
                                options={cornersOptions.concat(this.state.retailerCorners).sort(sortOn("label"))}
                                defaultValue={cornersOptions}
                                className={classes.textField}
                                placeholder={intl.formatMessage({id: "stockeasy.product.corners.select_corner"})}
                                disallowCreateNewOption={true}
                            />
                        </GridItem>
                        <GridItem xs={12} style={{marginBottom: '3.6em'}}>
                            <label
                              htmlFor={"description"}
                              className={classes.label}
                            >
                                {intl.formatMessage({id: "stockeasy.product.description.label"})}
                            </label>
                            <CKEditor
                                id={"description"}
                                variant="outlined"
                                error={!getValidationById('description', validations).valid}
                                onChange={(event, editor) => this.handleTextChange(editor.getData(), 'description')}
                                defaultValue={productData.description}
                                required
                                InputProps={this.getDefaultEndAdornment()}
                                className={classes.textField}
                                config={HTMLPurifierConfig}
                                editor={ClassicEditor}
                                key={"description"}
                            />
                        </GridItem>
                        { additionalMappingFields &&
                            <>
                                    { Object.keys(additionalMappingFields).map(key => {
                                        return (
                                            <GridItem xs={12} sm={6} key={key}>
                                                <TextField
                                                    key={key}
                                                    id={`additionalAttributes[${key}]`}
                                                    label={intl.formatMessage({id: `product.show.attribute.${key}`})}
                                                    placeholder={intl.formatMessage({id: `product.show.attribute.${key}`})}
                                                    variant="outlined"
                                                    value={this.state[`additionalFieldsInput${key}`]}
                                                    onChange={(e) => this.handleAdditionalAttributes(e, key, additionalMappingFields[key]['type'])}
                                                    defaultValue={productData.additionalAttributes && productData.additionalAttributes[key]}
                                                    InputLabelProps={{shrink: true}}
                                                    fullWidth
                                                    InputProps={this.getDefaultEndAdornment()}
                                                    inputProps={{maxLength: 255}}
                                                    className={classes.textField}
                                                />
                                            </GridItem>
                                        )
                                    })}
                            </>
                        }
                        { canManageCatalogSettings &&
                            <>
                                <GridItem xs={12}>
                                    <LabeledSwitch
                                        labelMessageId={"stockeasy.product.catalogOnly"}
                                        checked={productData.catalogOnly}
                                        onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, 'catalogOnly') }
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <LabeledSwitch
                                        labelMessageId={"stockeasy.product.hiddenPrice"}
                                        checked={productData.hiddenPrice}
                                        onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, 'hiddenPrice') }
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <LabeledSwitch
                                        labelMessageId={"stockeasy.product.privateProduct"}
                                        checked={productData.privateProduct}
                                        onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, 'privateProduct') }
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <LabeledSwitch
                                        labelMessageId={"stockeasy.product.privatePrice"}
                                        checked={productData.privatePrice}
                                        onChange={(e) => this.handleSwitchCatalogOnlyFeatureProperty(e, 'privatePrice') }
                                    />
                                </GridItem>
                            </>
                        }
                        <GridItem>
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        </GridItem>

                        <ButtonsWrapper
                            secondButtonLink={getStockEasyLink(this.props.member.retailers[0]['@id'].replace('/retailers/', ''))}
                            firstButtonContent={intl.formatMessage({id: "stockeasy.button.continue"})}
                            removeDraft={this.props.removeDraft}
                            submit={() => this.handleConfirmStep()}
                            step={1}
                        />

                    </GridContainer>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        product: state.stockEasy.request.retrieved,
        uploadSuccess: state.upload.success,
        uploadError: state.upload.error,
        uploadLoading: state.upload.loading,
        removeSuccess: state.upload.removeSuccess,
        member: state.authentication.member,
        categories: getDictionaryByType(getData(state.dictionaries.list.retrieved), 'category'),
        organizationCategories: state.organizationCategory.list.retrieved,
        brands: getDictionaryByType(getData(state.dictionaries.list.retrieved), 'brand'),
        authorizations: state.authentication.authorizations
    };
};

const mapDispatchToProps = dispatch => ({
    handleFileUpload: (file, type, object_id) => dispatch(handleFileUpload(file, type, object_id)),
    uploadReset: () => dispatch(reset()),
    removeFile: (id) => dispatch(removeFile(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(CreateProductFormStyle)(injectIntl(ProductStep)));
