
// utils
import {DisableVariationsFeedOptions} from "utils/DisableVariationsFeedOptions";

export default function hydrator(values) {
    if (values.disableVariationsNotSeenInAllFeeds) {
        values.disableVariationsNotSeen = DisableVariationsFeedOptions.ALL;
    } else if(values.disableVariationsNotSeenInThisFeed) {
        values.disableVariationsNotSeen = DisableVariationsFeedOptions.THIS;
    } else {
        values.disableVariationsNotSeen = DisableVariationsFeedOptions.NONE
    }

    return values;
}
