import React from "react";
import {connect} from "react-redux";
import {Tooltip} from "@mui/material";
import {withStyles} from "@mui/styles";
import showStyle from "../../../assets/jss/views/picking/order/showStyle";
import {FormattedMessage, injectIntl} from "react-intl";
import FormattedNumberWithCurrency from "../../../components/formattedNumberWithCurrencies/formattedNumberWithCurrency";
import {capitalize} from "../../../utils/capitalize";
import {ReportProblem} from "@mui/icons-material";
import * as moment from "moment";
import store from "../../../store";
import Options from "../order/components/LineItemRow/components/Options";

class PickingList extends React.Component {
    renderName = (item, variation) => {
        const {classes} = this.props;

        if (variation) {
            return (
                <div className={classes.tableCellColumn + " " + classes.name}>
                    <span className={classes.tableCellBold}>{item.name}</span>
                    <span><FormattedMessage id={"picking.order.show.table.name.ugs"}/> {variation.product.sku}</span>
                    <span><FormattedMessage id={"picking.order.show.table.name.sku"}/> {variation.sku}</span>
                    {variation.barcode &&
                    <span>
                            <FormattedMessage id={"picking.order.show.table.name.ean"}/> {variation.barcode}
                        </span>
                    }
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.size"}/>
                        {item.size ?
                            <b>{item.size.toUpperCase()}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.color"}/>
                        {item.color ?
                            <b>{item.color}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.brand"}/>
                        {item.brand ?
                            <b>{capitalize(item.brand.toLowerCase())}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                </div>
            );
        } else {
            return (
                <div className={classes.tableCellColumn + " " + classes.name}>
                    <div>
                        <span className={classes.tableCellBold}>{item.name}</span>
                        <Tooltip
                            className={classes.toolTipNa}
                            title={<FormattedMessage id={"picking.order.show.table.tooltip.na"}/>}
                        >
                            <ReportProblem/>
                        </Tooltip>
                    </div>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.ugs"}/>
                        <FormattedMessage id={"picking.order.show.table.na"}/>
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.sku"}/>
                        <FormattedMessage id={"picking.order.show.table.na"}/>
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.size"}/>
                        {item.size ?
                            <b>{item.size.toUpperCase()}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.color"}/>
                        {item.color ?
                            <b>{item.color}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                    <span>
                        <FormattedMessage id={"picking.order.show.table.name.brand"}/>
                        {item.brand ?
                            <b>{capitalize(item.brand.toLowerCase())}</b> :
                            <FormattedMessage id={"picking.order.show.table.na"}/>
                        }
                    </span>
                </div>
            );
        }
    };

    renderPrice = (price, initialRecommendedPrice) => {
        if (initialRecommendedPrice && initialRecommendedPrice !== price) {
            return (
                <div style={{ display:'flex', flexDirection: 'column'}}>
                    <b>
                       <FormattedNumberWithCurrency
                           value={price}
                           currency={this.props.currentOrganization?.currency}
                       />
                    </b>
                    <s>
                        <FormattedNumberWithCurrency
                            value={initialRecommendedPrice}
                            currency={this.props.currentOrganization?.currency}
                        />
                    </s>
                </div>
            )
        } else {
            return (
                <b>
                     <FormattedNumberWithCurrency
                         value={price}
                         currency={this.props.currentOrganization?.currency}
                     />
                </b>
            )
        }
    }

    render() {
        let sortedLineItems = [];
        if (this.props.retrieved) {
            const groupedByStatus = this.props.retrieved['hydra:member'].reduce((acc, currentItem) => {
                const status = currentItem.status;
                if (!acc[status]) {
                    acc[status] = {};
                }
                const shippingMethod = currentItem.shippingMethod;
                if (!acc[status][shippingMethod]) {
                    acc[status][shippingMethod] = [];
                }
                acc[status][shippingMethod].push(currentItem);

                return acc;
            }, {});

            sortedLineItems = Object.keys(groupedByStatus).map(status => {
                const shippingMethodGroups = groupedByStatus[status];
                const sortedShippingMethods = Object.keys(shippingMethodGroups).map(shippingMethod => {
                    const items = shippingMethodGroups[shippingMethod];
                    const sortedItems = items.sort((a, b) => {
                        if (a.name === b.name) {
                            return a.order.id.localeCompare(b.order.id);
                        }

                        return a.name.localeCompare(b.name);
                    });

                    return {shippingMethod, items: sortedItems};
                });

                return {status, shippingMethods: sortedShippingMethods};
            });
        }

        let totals = {'pending': 0, 'reserved': 0, 'returning-retailer': 0, 'returning-runner': 0};

        return (
            !!this.props.retrieved ? (
            <div style={{padding:'50px'}}>
                {!!this.props.retrieved && this.props.retrieved.length > 0 ? <h1>{ this.props.retrieved['hydra:member'][0].retailer.name }</h1> : <h1></h1>}
                <table width="100%">
                    <tbody>
                    {sortedLineItems.map(statusGroup => (
                        <React.Fragment key={statusGroup.status}>
                            <tr>
                                <td colSpan={3}
                                    style={{textTransform: 'uppercase', fontSize: '20px', paddingBottom: '10px'}}>
                                    <b>{this.props.intl.formatMessage({id: "picking.order.show.table.status"})} {statusGroup.status}</b>
                                </td>
                            </tr>
                            {statusGroup.shippingMethods.map(shippingMethodGroup => (
                                <React.Fragment key={shippingMethodGroup.shippingMethod}>
                                    <tr>
                                        <td colSpan={3} style={{
                                            textTransform: 'uppercase',
                                            fontSize: '20px',
                                            paddingTop: '10px',
                                            paddingBottom: '10px'
                                        }}>
                                            <b>{this.props.intl.formatMessage({id: `picking.order.shippingMethod.${shippingMethodGroup.shippingMethod}`})}</b>
                                        </td>
                                    </tr>
                                    {shippingMethodGroup.items.map((item, key) => {
                                        totals[item['status']] += item.retailerPrice;
                                        let brand = item.brand ?? item.variation?.product?.brand?.name ?? this.props.intl.formatMessage({id: "picking.order.show.table.na"});
                                        let color = item.color ?? item.variation?.color?.name ?? this.props.intl.formatMessage({id: "picking.order.show.table.na"});
                                        let size = item.size ?? item.variation?.size?.name ?? this.props.intl.formatMessage({id: "picking.order.show.table.na"});
                                        let refProduct = item.productSku ?? item.variation?.product?.sku ?? this.props.intl.formatMessage({id: "picking.order.show.table.na"});
                                        let refVariation = item.variationSku ?? item.variation?.sku ?? this.props.intl.formatMessage({id: "picking.order.show.table.na"});

                                        return (
                                            <tr key={key} style={{marginTop: '10px'}}>
                                                <td align="left" style={{lineHeight: '14px', paddingTop: '10px'}}>
                                                    <b>{item.name}</b><br/>
                                                    <span style={{fontSize: '12px'}}>
                                                            {this.props.intl.formatMessage({id: "picking.order.show.table.name.ugs"})} {refProduct}<br/>
                                                            {this.props.intl.formatMessage({id: "picking.order.show.table.name.sku"})} {refVariation}<br/>
                                                            <Options lineItem={item} separator={<br/>}/>
                                                            {this.props.intl.formatMessage({id: "picking.order.show.table.name.brand"})} <b>{brand}</b><br/>
                                                        </span>
                                                </td>
                                                <td style={{paddingTop: '10px'}}>
                                                    {(item.adjustments && item.adjustments.length) ?
                                                        this.renderPrice(item.amount,  item.retailerPrice)
                                                        :
                                                        this.renderPrice(item.retailerPrice,  item.initialRecommendedPrice / 100)
                                                    }
                                                </td>
                                                <td align="right" style={{
                                                    paddingTop: '10px',
                                                    lineHeight: '18px',
                                                    fontSize: '15px'
                                                }}>
                                                    {this.props.intl.formatMessage({id: "picking.order.show.table.status"})}
                                                    <b>{item.status}</b><br/>
                                                    {this.props.intl.formatMessage({id: "picking.order.show.table.orderId"})}
                                                    <b>{item.order.externalId}</b><br/>
                                                    <b>{this.props.intl.formatMessage({id: `picking.order.shippingMethod.${item.shippingMethod}`})}</b><br/>
                                                    <b>{moment(item.createdAt).format('DD/MM/YYYY hh:mm')}</b><br/>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                    <tr key='totalPending'>
                        <td colSpan={3} align='right'><b>Total pending:</b></td>
                        <td align='left'><FormattedNumberWithCurrency value={totals['pending']}
                                                                      currency={this.props.currentOrganization?.currency}/>
                        </td>
                    </tr>
                    <tr key='totalReserved'>
                        <td colSpan={3} align='right'><b>Total reserved:</b></td>
                        <td align='left'><FormattedNumberWithCurrency value={totals['reserved']}
                                                                      currency={this.props.currentOrganization?.currency}/>
                        </td>
                    </tr>
                    <tr key='totalReturningRetailer'>
                        <td colSpan={3} align='right'><b>Total returning retailer:</b></td>
                        <td align='left'><FormattedNumberWithCurrency value={totals['returning-retailer']}
                                                                      currency={this.props.currentOrganization?.currency}/>
                        </td>
                    </tr>
                    <tr key='totalReturningRunner'>
                        <td colSpan={3} align='right'><b>Total returning runner:</b></td>
                        <td align='left'><FormattedNumberWithCurrency value={totals['returning-runner']}
                                                                      currency={this.props.currentOrganization?.currency}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>)
                :
                (<div></div>)
        );
    }
}

const mapStateToProps = state => {
    return {
        retrieved: state.lineItem.retailerList.retrieved,
        currentOrganization: state.currentOrganization.retrieved ?? null,
    };

};

export default connect(
    mapStateToProps,
    null
)(withStyles(showStyle)(injectIntl(PickingList)));
