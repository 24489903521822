import React, {useContext, useEffect, useState} from 'react';
import DefaultContainer from "../../../../components/containers/DefaultContainer";
import {AppBar, Grid, Tab, Tabs} from "@mui/material";
import Card from "../../../../components/card/card";
import {FormattedDate, FormattedMessage, injectIntl} from "react-intl";
import FoldingCard from "../../../../components/card/FoldingCard";
import {get} from "../../../../api/product/list";
import ProductResume from "./components/ProductResume";
import ProductPreferences from "./components/ProductPreferences";
import VariationListing from "./components/VariationListing";
import SaveButton from "../../../../components/button/SaveButton";
import ProductForm from "./components/ProductForm";
import {ProductShowContext} from "./context/ProductShowContext";
import genderList from "api/gender/list";
import brandList from "../../../../api/brand/list";
import taxRateList from "api/taxRates/list";
import categoryList from 'api/category/list';
import {cornerList} from "api/corner/list";

import {StoreContext} from "../../../../contexts/storeContext";
import VariationForm from "./components/VariationForm";
import {getByRetailerGroupedByOptions} from "../../../../api/retailerOptionValues/list";
import {Authorizations, hasAuthorization} from "../../../../utils/authorizations";
import PriceChart from "./components/priceChart";
import {getTranslation} from "../../../../domain/helpers/translations";
import AddIcon from "../../../../components/icons/AddIcon";

function Show(props) {
    const productId = decodeURIComponent(props.match.params.prid);

    const [product, setProduct] = useState(null);
    const [selectedVariation, setSelectedVariation] = useState(null);

    const [genders, setGenders] = useState([]);
    const [brands, setBrands] = useState([]);
    const [taxRates, setTaxRates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [corners, setCorners] = useState([]);
    const [retailerOptionsValues, setRetailerOptionValues] = useState([]);

    const [optionsCount, setOptionCount] = useState(0);

    const [variantsCombinaisons, setVariantsCombinaisons] = useState([]);

    const [showCreateVariation, setShowCreateVariation] = useState(false)

    const [isStockEasyUser, setIsStockEasyUser] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const store = useContext(StoreContext);
    const currentRetailer = store.getState()?.retailer?.show?.retrieved;
    const userAuthorizations = store.getState()?.authentication.authorizations;

    useEffect(() => {
        refreshProduct();
        genderList().then(genders => setGenders(genders));
        brandList().then(brands => setBrands(brands))
        taxRateList().then(taxRates => setTaxRates(taxRates))
        categoryList().then(categories => setCategories(categories))
    }, []);

    // when we got authorization from the store
    useEffect(() => {
        const isStockEasyUser = hasAuthorization(userAuthorizations, Authorizations.STOCK_EASY_ACCESS);

        setIsStockEasyUser(isStockEasyUser);

        let cornerRequestParam = null;

        if (isStockEasyUser) {
            cornerRequestParam = "enabled=true&public=true";
        }

        cornerList(cornerRequestParam).then(corners => setCorners(corners));
    }, [userAuthorizations]);

    // when we got currentRetailer from the store and retrieved product
    useEffect(() => {
        const retailerId = currentRetailer?.id

        if (!retailerId || !product) return;

        const availableOptionInProduct = product.variations.flatMap((variation) => variation.retailerOptionValues.map(rov => rov.option.id))

        let uniqueAvailableOptionInProduct = [...new Set(availableOptionInProduct)];

        setOptionCount(uniqueAvailableOptionInProduct.length);

        getByRetailerGroupedByOptions(retailerId, uniqueAvailableOptionInProduct).then((result) => setRetailerOptionValues(result))
    }, [currentRetailer, product]);

    if (!product) {
        return null;
    }

    async function refreshProduct() {
        const retrievedProduct = await get(productId).then(retrievedProduct => {
            setProduct(retrievedProduct);

            setShowCreateVariation(false);

            createArrayOfVariantCombinaisons(retrievedProduct.variations);

            return retrievedProduct;
        });

        if (props.history.location.type && props.history.location.type === 'variation') {
            refreshSelectedVariationFormValues(retrievedProduct.variations, props.history.location.variation.id);
        }

        if (selectedVariation) {
            refreshSelectedVariationFormValues(retrievedProduct.variations, selectedVariation?.id);
        }

        return retrievedProduct;
    }

    function refreshSelectedVariationFormValues(variationList, selectedVariationId) {
        if (!selectedVariationId) return;

        setShowCreateVariation(false);

        setSelectedVariation(null);

        setSelectedVariation(findVariationByIdInVariationList(selectedVariationId, variationList))
    }

    function switchVariation(variationId) {
        setShowCreateVariation(false);

        setSelectedVariation(null)

        if (!variationId) return;

        setSelectedVariation(findVariationByIdInVariationList(variationId, product.variations))
    }

    function findVariationByIdInVariationList(variationId, variationList) {
        const variationFound = variationList.find((variation) => variation.id === variationId);

        return variationFound ?? null;
    }

    function orderFormattedSelectOptionsByLabel(formattedSelectOptions) {
        return formattedSelectOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    function createArrayOfVariantCombinaisons(variations) {
        const variantCombinaisons = variations.map((variation) => variation.retailerOptionValues.map((rov) => rov['@id']))

        setVariantsCombinaisons(variantCombinaisons);
    }

    function showVariationCreationForm() {
        setShowCreateVariation(true);
        setSelectedVariation(null);
    }

    return (
        <DefaultContainer>
            <ProductShowContext.Provider value={{
                refreshProduct: refreshProduct,
                genderList: genders,
                brandList: brands,
                taxRateList: taxRates,
                categoryList: categories,
                cornerList: corners,
                retailerOptionsValueList: retailerOptionsValues,
                product,
                selectedVariation,
                switchVariation,
                variantsCombinaisons,
                orderFormattedSelectOptionsByLabel,
                currentRetailer,
                isStockEasyUser,
                userAuthorizations,
                refreshSelectedVariationFormValues,
                optionsCount
            }}>
                <Grid
                    container
                    spacing={2}
                    marginTop={0}
                    marginBottom={3}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        lg={3.5}
                        direction={'column'}
                        rowSpacing={2}
                    >
                        <Grid item>
                            <FoldingCard
                                titleId='product.show.resume.title'
                                backgroundOnHover={true}
                                selected={selectedVariation === null}
                            >
                                <ProductResume
                                    image={product.pictures[0]?.contentUrl}
                                    status={product.status}
                                    name={getTranslation(product).name}
                                    eshopId={product.eshopId}
                                    sku={product.sku}
                                    price={product.highestPrice}
                                    stock={product.stock}
                                    updatedAt={<FormattedDate value={product.updatedAt} />}
                                />
                            </FoldingCard>
                        </Grid>
                        <Grid item>
                            <FoldingCard titleId='product.show.preferences.title'>
                                <ProductPreferences product={product} />
                            </FoldingCard>
                        </Grid>
                        <Grid item style={{maxWidth: '-webkit-fill-available'}}>
                            <FoldingCard titleId='product.show.variations.title'>
                                <SaveButton
                                    sx={{ marginBottom: '32px' }}
                                    onClick={() => showVariationCreationForm()}
                                >
                                    <AddIcon />
                                    <FormattedMessage id='product.show.variations.add' />
                                </SaveButton>
                                <VariationListing variations={product.variations} />
                            </FoldingCard>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8.5}>
                        <AppBar
                            position="static"
                            color="default"
                            sx={{
                                background: '#fff',
                                boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.04)',
                                borderRadius: '8px',
                            }}
                        >
                            <Tabs
                                value={activeTab}
                                onChange={(event, value) => setActiveTab(value)}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="scrollable auto tabs"
                                sx={{ borderRadius: '8px' }}
                            >
                                <Tab
                                    label={props.intl.formatMessage({id: 'product.show.tab.information'})}
                                    id='information'
                                    aria-controls='information'
                                />
                                {selectedVariation !== null && (
                                    <Tab
                                        label={props.intl.formatMessage({id: 'product.show.tab.priceAndStockHistory'})}
                                        id='history'
                                        aria-controls='history'
                                    />
                                )}
                            </Tabs>
                        </AppBar>
                        <div
                            hidden={activeTab !== 0}
                            role="tabpanel"
                            id='information'
                            aria-labelledby='information'
                        >
                            {showCreateVariation ? (
                                <VariationForm variation={null} />
                            ) : selectedVariation !== null ? (
                                <VariationForm variation={selectedVariation} />
                            ) : (
                                <ProductForm product={product} />
                            )}
                        </div>
                        {selectedVariation !== null && (
                            <div
                                hidden={activeTab !== 1}
                                role="tabpanel"
                                id='history'
                                aria-labelledby='history'
                            >
                                <Card>
                                    <Grid item xs={12}>
                                        <PriceChart currentVariation={selectedVariation} />
                                    </Grid>
                                </Card>
                            </div>
                        )}

                    </Grid>
                </Grid>
            </ProductShowContext.Provider>
        </DefaultContainer>
    );
}

export default injectIntl(Show);
