import React, {Component} from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl";

// @mui/material components
import {
    Stepper,
    Step,
    Tab,
    Tabs
} from "@mui/material";

// core components
import CardAttribute from "./components/cardAttribute"
import Loader from "./components/Loader";
import StepName from "./components/Step/StepName";
import BackButton from "./components/BackButton";
import MappingListContainer from "./components/MappingListContainer";
import MappingElement from "./components/MappingElement";
import CustomTabPanel from "./components/CustomTabPanel";
import StepButton from './components/Step/StepButton';
import MappingFilter from "./components/MappingFilter";

// actions
import {list, reset} from "actions/feed/attribute/list";

// utils
import abort from "utils/abort";
import {getTranslation} from "domain/helpers/translations";

// enums
import {AllProductStepEnums, ProductStepEnums} from "./enums/ProductStepEnums";
import {AllMappingTabEnums, MappingTabEnums} from "./enums/MappingTabEnums";

class Attributes extends Component {
    static propTypes = {
        retailerId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeStep: {
                index: 0
            },
            activeTab: MappingTabEnums.UNMAPPED,
            attributesMapped: [],
            attributeCount: [],
            inStockActive: false,
            inValid: false,
            hasJustBeenMapped: []
        };

        this.inStockActiveSearchParamsKey = 'in-stock-active';
        this.inValidSearchParamsKey = 'in-valid';
    }

    componentDidMount() {
        this.setStateFromSearchParams();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.attributesMapped !== this.state.attributesMapped && nextState.attributesMapped.length > 0) {
            return false;
        }

        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // prevent double nextProps.list because of resetUpdate

        if (this.props.attributeRetrieved !== nextProps.attributeRetrieved) {
            nextProps.attributeRetrieved.categoryAttributes = nextProps.attributeRetrieved.categoryAttributes.sort((categA, categB) => categA.trash && !categB.trash ? 1 : -1);

            this.setState({
                hasJustBeenMapped: []
            });

            this.setAttributesCount(nextProps.attributeRetrieved, () => {
                this.switchToAvailableStep(nextProps.attributeRetrieved);
            });
        }
    };

    componentWillUnmount() {
        this.props.reset()
        abort.abortRequests();
    }

    getAttributes = () => {
        const isMappedView = MappingTabEnums.MAPPED === this.state.activeTab;

        this.props.list(
            this.props.retailerId,
            isMappedView,
            this.state.inStockActive,
            this.state.inValid,
        )
    }

    setAttributesCount = (attributeRetrieved, callback) => {
        const attributeCount = {};

        attributeCount[ProductStepEnums.CATEGORIES] = attributeRetrieved?.['categoryCountAttributes'] ?? 0;
        attributeCount[ProductStepEnums.GENDERS] =  attributeRetrieved?.['genderCountAttributes'] ?? 0;

        const retailerOptionValues = Object.entries(attributeRetrieved['retailerOptionValues']);

        for (let [optionId, values] of retailerOptionValues) {
            attributeCount[optionId] = values['retailerOptionValuesCount'];
        }

        this.setState({
            attributeCount
        }, () => callback());
    }

    switchToAvailableStep = (attributeRetrieved) => {
        let steps = this.renderSteps(attributeRetrieved);

        // if a step is already selected
        if (this.state.activeStep.id) {
            const nonEmptySteps = steps.filter((step) => this.state.attributeCount[step.id] > 0);

            // if currentState has no attributes left -> go to first available step
            if (this.state.attributeCount[this.state.activeStep.id] === 0) {
                this.setState({activeStep: nonEmptySteps[0]})
            }
        }
    }

    setStateFromSearchParams() {
        const params = new URLSearchParams(this.props.history.location.search);

        const inStockActive = params.has(this.inStockActiveSearchParamsKey)
            ? JSON.parse(params.get(this.inStockActiveSearchParamsKey))
            : false;

        const inValid = params.has(this.inValidSearchParamsKey)
            ? JSON.parse(params.get(this.inValidSearchParamsKey))
            : false;

        this.setState({
            inStockActive: inStockActive,
            inValid: inValid
        }, () => {
            this.getAttributes();
        });
    };

    decrementTotalMappingToDoByMappingStep = (stepName) => {
        this.setState((prevState) => ({
            attributeCount: {
                ...prevState.attributeCount,
                [stepName]: prevState.attributeCount[stepName] - 1
            }
        }));
    };

    // only for categories or genders
    mapValue = (item) => {
        const {activeStep} = this.state;

        let tableName = '';

        if (activeStep.index === 0) {
            tableName = ProductStepEnums.CATEGORIES;
        } else if (activeStep.index === 1) {
            tableName = ProductStepEnums.GENDERS;
        }

        item.value = tableName;
        if (item.trash) {
            item.trashCategories = [item.name]
        }
        if (item.untrash) {
            item.untrashCategories = [item.name]
        }
        let data = {[tableName]: item};

        this.decrementTotalMappingToDoByMappingStep(tableName);

        this.props.onSubmitEvent(
            data,
            this.props.retailerId
        );
    };

    getLabels(attributeRetrieved, activeStep) {
        if (attributeRetrieved) {
            switch (activeStep.index) {
                case 0 :
                    return {attributeName: ProductStepEnums.CATEGORIES, values: attributeRetrieved["categoryAttributes"]};
                case 1 :
                    return {attributeName: ProductStepEnums.GENDERS, values: attributeRetrieved["genderAttributes"]};
                default :
                    const selectedRetailerOptionValues = attributeRetrieved['retailerOptionValues'][activeStep.id];
                    return {
                        attributeName: getTranslation(selectedRetailerOptionValues.option).name,
                        retailerOptionValues: selectedRetailerOptionValues['retailerOptionValues'],
                        optionValues: selectedRetailerOptionValues['optionValues']
                    };
            }
        }

        return false
    }

    renderSteps(attributeRetrieved) {
        const {intl} = this.props;

        let steps = AllProductStepEnums.map((attributeName, index) => ({
            label: intl.formatMessage({id: `product.show.attribute.step.label.${attributeName}`}),
            index: index,
            id: attributeName
        }));

        if (attributeRetrieved?.retailerOptionValues) {
            Object.values(attributeRetrieved?.retailerOptionValues)
                .forEach((option, index) => {
                    steps.push({
                        label: getTranslation(option.option).name,
                        index: index + 2, // + 2 because categories is 0 et genders is 1
                        id: option.option.id
                    })
                })
            ;
        }

        return steps;
    }

    handleToggleInStock = () => {
        this.setState(
            { inStockActive: !this.state.inStockActive },
            function() {
                const params = new URLSearchParams(this.props.history.location.search);

                params.set(this.inStockActiveSearchParamsKey, this.state.inStockActive);

                this.props.history.push(`?${params.toString()}`);

                this.getAttributes();
            }
        );
    };

    handleToggleInValid = () => {
        this.setState(
            { inValid: !this.state.inValid },
            function() {
                const params = new URLSearchParams(this.props.history.location.search);

                params.set(this.inValidSearchParamsKey, this.state.inValid);

                this.props.history.push(`?${params.toString()}`);

                this.getAttributes();
            }
        );
    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            attributesMapped: [],
            activeTab: newValue,
        }, () => {
            this.getAttributes();
        });
    };

    addMappedRetailerOptionValueToJustDoneMappingList = (elementId, stepName) => {
        const isMappedView = this.state.activeTab === MappingTabEnums.MAPPED;

        if (isMappedView) {
            return;
        }

        this.setState((prevState) => ({
            hasJustBeenMapped: [...prevState.hasJustBeenMapped, elementId]
        }), () => {
            this.decrementTotalMappingToDoByMappingStep(stepName)
        });
    }

    formatOptionValuesForOptionValuesList = (optionValues)  => {
        let finalOptionsValues = optionValues.map((optionValue) => ({
            "@id": `/optionValues/${optionValue.id}`,
            label: getTranslation(optionValue).name,
            value: optionValue.id
        }));

        if (finalOptionsValues && typeof finalOptionsValues !== undefined) {
            finalOptionsValues = finalOptionsValues.sort(function (a, b) {
                return a.label.length - b.label.length || a.label.localeCompare(b.label)
            });
        }

        return finalOptionsValues;
    }

    renderMappingList = () => {
        const {attributeRetrieved} = this.props;
        const {activeStep, activeTab} = this.state;

        const labels = this.getLabels(attributeRetrieved, activeStep);

        if (AllProductStepEnums.includes(labels['attributeName'])) {
            const isMappedView = activeTab === MappingTabEnums.MAPPED;

            let labelName = '';
            let labelNameId = '';
            let labelData = '';

            if (labels['attributeName'] === ProductStepEnums.CATEGORIES ) {
                labelName = 'category_dictionary_id';
                labelNameId = 'category_id';
                labelData = ProductStepEnums.CATEGORIES;
            } else if (labels['attributeName'] === ProductStepEnums.GENDERS) {
                labelName = 'id';
                labelNameId = 'id';
                labelData = ProductStepEnums.GENDERS;
            }

            return labels?.values.map((item, key) => {
                if ((!this.state.attributesMapped.includes(item[labelName]) && !isMappedView) || isMappedView) {
                    return (
                        <CardAttribute
                            key={key}
                            item={item}
                            labelName={labelName}
                            labelNameId={labelNameId}
                            labelData={labelData}
                            labels={labels}
                            attributeRetrieved={this.props.attributeRetrieved}
                            mapValue={item => this.mapValue(item)}
                            activeTab={activeTab}
                            activeStep={this.state.activeStep}
                        />
                    );
                }
            });
        }

        const formattedOptionValues = this.formatOptionValuesForOptionValuesList(labels.optionValues)

        // remove retailerOptionValue that has just been mapped
        const retailerOptionValues = Object.values(labels.retailerOptionValues).filter((retailerOptionValue) => (
            !this.state.hasJustBeenMapped.includes(retailerOptionValue.value.id)
        ))

        return retailerOptionValues.map((retailerOptionValue) => (
            <MappingElement
                key={retailerOptionValue.value.id}
                retailerOptionValue={retailerOptionValue}
                optionValues={formattedOptionValues}
                onMap={(id, stepName) => this.addMappedRetailerOptionValueToJustDoneMappingList(id, stepName)}
            />
        ));
    }

    renderStepper = () => {
        const {attributeRetrieved, attributeLoading} = this.props;
        const {activeStep, attributeCount} = this.state;

        let steps = this.renderSteps(attributeRetrieved);

        // keep only non-empty steps
        steps = steps.filter((step) => attributeCount[step.id] > 0)

        return (
            <Stepper
                alternativeLabel
                nonLinear
                activeStep={activeStep.index}
                sx={{marginTop: '20px', height: "150px"}}
            >
                {steps.map((step) => (
                    <Step
                        key={step.label}
                        // can't create styled component with this legacy css
                        sx={step.index !== 0 ? {
                            "& > div:first-of-type": {
                                top: "25px",
                                left: "calc(-50% + 27px)"
                            },
                        } : null}
                    >
                        <StepButton
                            isSelectedStep={activeStep.index === step.index}
                            onClick={() => this.setState({activeStep: step})}
                        >
                            <StepName
                                name={step.label}
                                count={attributeCount[step.id]}
                                loading={attributeLoading}
                                isStepSelected={activeStep.index === step.index}
                            />
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        )
    }

    render() {
        const {intl} = this.props;

        if (!this.props.attributeRetrieved) {
            return <Loader loading={true} />
        }

        return (
            <div>
                <MappingFilter
                    messageId={'mapper.form.attr.inStock'}
                    checked={this.state.inStockActive}
                    onClick={() => this.handleToggleInStock()}
                />
                <MappingFilter
                    messageId={'mapper.form.attr.valid'}
                    checked={this.state.inValid}
                    onClick={() => this.handleToggleInValid()}
                />
                <Tabs
                    value={this.state.activeTab}
                    onChange={(event, value) => this.handleChangeTab(event, value)}
                >
                    {AllMappingTabEnums.map((value) => (
                        <Tab
                            key={value}
                            label={intl.formatMessage({id: `mapper.form.tab.${value}`})}
                            value={value}
                        />
                    ))}
                </Tabs>
                <MappingListContainer>
                    {AllMappingTabEnums.map((value) => (
                        <CustomTabPanel
                            activeTab={this.state.activeTab}
                            index={value}
                            key={value}
                        >
                            {this.renderStepper()}
                            {this.props.attributeLoading ? (
                                <Loader loading={true} />
                            ) : (
                                this.renderMappingList()
                            )}
                        </CustomTabPanel>
                    ))}
                    <BackButton retailerId={this.props.retailerId} />
                </MappingListContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        attributeLoading: state.attribute.list.loading,
        attributeRetrieved: state.attribute.list.retrieved,
    };
};

const mapDispatchToProps = dispatch => ({
    list: (retailerId, isMapped, inStock, inValid) => dispatch(list(retailerId, isMapped, inStock, inValid)),
    reset: () => dispatch(reset()),
});

export default (connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Attributes)));
