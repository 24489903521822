import React from 'react';
import PropTypes from 'prop-types';

import Infos from './components/Infos';
import Banner from './components/Banner';
import Logo from './components/Logo';

import {getTranslation} from "domain/helpers/translations";

Index.propTypes = {
    retailer: PropTypes.object.isRequired
};

function Index({retailer}) {
    const retailerTranslations = getTranslation(retailer);

    return (
        <div style={{
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 4px 22px 0px rgba(0, 0, 0, 0.06)',
            height: '100%'
        }}>
            <Banner url={retailerTranslations?.picture?.contentUrl} />
            <Logo url={retailerTranslations?.logo?.contentUrl} />
            <Infos
                name={retailer.name}
                description={retailerTranslations?.description}
                city={retailer.addressVO?.city}
                email={retailer.email}
                id={retailer.id}
            />
        </div>
    );
}

export default Index;
